.form-field {
  @apply flex flex-col gap-y-3;
}

.form-label {
  @apply text-[13px] text-[#4C5464] font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70;
}

.form-input {
  @apply flex
  w-full
  h-[51px]
  rounded-[46px]
  border
  border-[#0000006B]
  bg-white
  px-[21px]
  py-2
  text-sm
  ring-offset-white
  file:border-0
  file:bg-transparent
  file:text-sm
  file:font-medium
  text-[#4C5464]
  placeholder:text-[#83878B]
  focus-visible:outline-none
  focus-visible:ring-2
  focus-visible:ring-primary
  focus-visible:ring-offset-1
  disabled:cursor-not-allowed
  disabled:opacity-50;

  &.ng-invalid:not(.ng-untouched) {
    @apply border-red-500;
  }
}

.form-textarea {
  @apply flex
  w-full
  rounded-[20px]
  border
  border-[#0000006B]
  bg-white
  p-3
  text-sm
  ring-offset-white
  file:border-0
  file:bg-transparent
  file:text-sm
  file:font-medium
  text-[#4C5464]
  placeholder:text-[#83878B]
  focus-visible:outline-none
  focus-visible:ring-2
  focus-visible:ring-primary
  focus-visible:ring-offset-1
  disabled:cursor-not-allowed
  disabled:opacity-50;

  &.ng-invalid:not(.ng-untouched) {
    @apply border-red-500;
  }
}

.form-select {
  @apply flex
  w-full
  rounded-[20px]
  border
  border-[#0000006B]
  bg-white
  p-3
  text-sm
  ring-offset-white
  file:border-0
  file:bg-transparent
  file:text-sm
  file:font-medium
  text-[#4C5464]
  placeholder:text-[#83878B]
  focus-visible:outline-none
  focus-visible:ring-2
  focus-visible:ring-primary
  focus-visible:ring-offset-1
  disabled:cursor-not-allowed
  disabled:opacity-50;

  &.ng-invalid:not(.ng-untouched) {
    @apply border-red-500;
  }
}

.form-input-outline {
  @apply flex
  w-full
  h-[51px]
  border-b-2
  border-b-[#0000006B]
  bg-white
  px-[21px]
  py-2
  text-sm
  text-[#4C5464]
  placeholder:text-[#83878B]
  transition-colors
  focus-visible:outline-none
  focus-visible:border-b-primary
  disabled:cursor-not-allowed
  disabled:opacity-50;
}

.form-message-error {
  @apply block text-red-500 text-xs font-medium;
}
