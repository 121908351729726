/* Button */
.btn,
.btn-icon {
  @apply flex items-center justify-center transition-colors duration-300 cursor-pointer;
}

.btn:disabled,
.btn-icon:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.btn {
  @apply border uppercase;
  /* Sizes */
  &-xs {
    @apply text-xs rounded-xl px-2 py-1 gap-x-4;
  }
  &-sm {
    @apply text-sm rounded-3xl px-4 py-2 gap-x-4;
  }
  &-md {
    @apply text-sm h-11 rounded-[44px] px-11 gap-x-4;
  }

  /*   &-md:has(svg) {
    @apply pl-8 pr-2;
  } */
}

/* Icon Button */
.btn-icon {
  @apply border rounded-full;
}

/* Buttons Colors */
.btn-solid-primary {
  @apply bg-primary border-transparent text-white hover:bg-primary-500;
}

.btn-outline-primary {
  @apply bg-transparent border-primary text-primary hover:bg-primary-100 hover:bg-opacity-20;
}

.btn-solid-danger {
  @apply bg-[#F15140] border-transparent text-white hover:bg-red-600;
}

.btn-outline-danger {
  @apply bg-transparent border-[#F15140] text-[#F15140] hover:bg-red-400 hover:bg-opacity-20;
}
