.switch {
	width: 43px;
	height: 24px;
	position: relative;
	display: inline-block;

	input {
		display: none;

		&:not(:checked) +.switch-state {
      @apply bg-gray-200;

			&:before {
        @apply bg-gray-400;
			}
		}

		&:checked+.switch-state {
      @apply bg-primary-100;

			&:before {
				transform: translateX(19px);
        @apply bg-primary;
			}
		}

		&:focus+.switch-state {
			@apply shadow;
		}
	}
}

.switch-state {
	border-radius: 20px;
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: .4s;

	&:before {
		position: absolute;
		content: "";
		height: 20px;
		width: 20px;
		left: 2px;
		bottom: 2px;
		
		transition: .4s;
		border-radius: 20px;

    // @apply bg-primary;
	}
}