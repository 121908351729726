@tailwind base;
@tailwind utilities;
@tailwind components;
@import "~mapbox-gl/dist/mapbox-gl.css";
@import '~@ng-select/ng-select/themes/default.theme.css';

@import './styles/components/form';
@import './styles/components/switch';
@import './styles/components/buttons';
@import './styles/components/breadcrumb';

.leaflet-popup-content-wrapper {
  padding: 0;
  background: transparent;
}

.leaflet-popup-content {
  margin: 0;
  background: transparent;
}

mgl-map {
  height: 100%;
  width: 100%;
}

.custom-marker-map {
  width: 50px;
  height: 50px;
}

/* You can add global styles to this file, and also import other style files */
// @import './assets/scss/app.scss';
